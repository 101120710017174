import React from 'react'

// This is the separate button group style tab nav as seen
// in the create tab modal and within the payment flow.

export default function ButtonTabNav({
    id=null,
    className='',
    containerClassName='',
    tabs=[],
    currentTab=null,
    onChange=() => {},
}) {
    const tabSlug = !!currentTab && (typeof currentTab === 'string')
        ? currentTab
        : (currentTab?.slug || 'UNKNOWN')

    return tabs.length === 0 ? null : (
        <nav id={id} className={`button-tab--navigation ${containerClassName}`.trim()}>
            <div id={id} className={`button-tab--navigation--inner ${className}`.trim()}>
                {
                    tabs.map((tab, index) => (
                        <button
                            key={`button-tab-${index}`}
                            dangerouslySetInnerHTML={{ __html: (tab?.label || 'UNKNOWN') }}
                            type='button'
                            className={`text-nowrap ${tabSlug === tab?.slug ? 'active' : ''}`}
                            disabled={tab?.disabled}
                            onClick={(e) => onChange(tab, e)}
                        />
                    ))
                }
            </div>
        </nav>
    )
}
