import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { selectCurrentUser } from '@/features/Session/sessionSlice'

import {
    fetchCheck,
    fetchChecks,
    fetchResourceTypes,
    selectAllOpenChecks,
    selectChecksLastUpdatedAt,
    selectResourceTypes,
    setCurrentCheck,
    setCurrentComponent,
    resetResourceTypes,
    updateOpenCheckPreference,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import Breadcrumbs from '@/features/AdvancedPointOfSale/components/Breadcrumbs'
import ChecksGrid from '@/features/AdvancedPointOfSale/components/ChecksGrid'
import NewCheckButton from '@/features/AdvancedPointOfSale/components/buttons/NewCheckButton'
import TabNav from '@/features/AdvancedPointOfSale/components/tabs/TabNav'
import Icon from '@/components/FontAwesomeIcon'
import { parameterize, titleize } from 'inflected'
import scrollHinting from '@/lib/ScrollHinting'
import { PREVENT_LOADER } from '@/lib/Storage'
import { debug } from '@/lib/Debug'

const defaultTabs = [
    { id: -2, title: 'All' },
    { id: -1, title: 'Retail Checks' },
]

export default function OpenChecks({
    self='OpenChecks',
    transactionsAllowed=false,
    onChange=() => {},
}) {

    const dispatch                    = useDispatch()
    const currentUser                 = useSelector(selectCurrentUser)
    const resourceTypes               = useSelector(selectResourceTypes)
    const allOpenChecks               = useSelector(selectAllOpenChecks)
    const lastUpdatedAt               = useSelector(selectChecksLastUpdatedAt)
    const [tabs, setTabs]             = useState(defaultTabs)
    const [currentTab, setCurrentTab] = useState(defaultTabs[0])
    const [loading, setLoading]       = useState(null)

    const filteredChecks = useMemo(() => {
        if (!currentUser || !allOpenChecks) { return [] }

        const tabName = parameterize(currentTab.title.replace(/[\(\)]/g, ''))
        const checks  = currentUser.show_open_checks_for_everyone ? allOpenChecks : allOpenChecks.filter((c) => c.server?.id === currentUser.id)

        switch(tabName) {
            case 'all' :
                return checks

            // ONLY unassigned/anonymous/retail-checks
            case 'retail-checks' :
                return checks.filter((c) => !c.resource_type_id)

            default :
                return checks.filter((c) => (
                    c.resource_type_id === currentTab.id
                ))
        }
    }, [currentUser.id, currentUser.show_open_checks_for_everyone, allOpenChecks, currentTab])

    const dispatchFetchChecks = ({ skippable }) => {
        setLoading(true)

        // IF lastUpdatedAt is set and filteredChecks are present...
        // AND IF the last time we fetched them was within X seconds ago..
        // THEN do not fetch them again
        if (
            skippable === true
            && !!lastUpdatedAt
            && allOpenChecks.length > 0
            && moment(Date.now()).diff(moment(lastUpdatedAt), 'seconds') < 15
        ) {
            if (debug && console) { console.log('SKIPPING CHECKS FETCH') }
            setLoading(false)

        // OTHERWISE fetch the checks
        } else {
            dispatch(fetchChecks(currentUser.show_open_checks_for_everyone ? null : currentUser.id, {
                exclude_check_readiness: true,
                for_checks_grid: true,
            }))
            .finally(() => {
                setLoading(false)
                scrollHinting.search()
            })
        }
    }

    const handleToggleSelfOrEveryone = () => {
        dispatch(updateOpenCheckPreference())
    }

    const handleChangeTab = (tab, e) => {
        setCurrentTab(tab)
        scrollHinting.search()
        e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
    }

    const handleCheckClick = ({ id }) => {
        dispatch(fetchCheck(id)).then((check) => {
            dispatch(setCurrentCheck(check))
            dispatch(setCurrentComponent('DefaultMenu'))
        })
    }

    // load data/dependencies if necessary
    useEffect(() => {
        window.sessionStorage.setItem(PREVENT_LOADER, true)

        onChange(
            <Breadcrumbs
                id='advanced-pos--terminal-navigation--breadcrumbs'
                data={[ { id: null, name: titleize(self), type: null } ]}
            />
        )

        if (resourceTypes.length === 0) {
            dispatch(fetchResourceTypes())
        }

        return () => {
            dispatch(resetResourceTypes())
            window.sessionStorage.removeItem(PREVENT_LOADER)
        }
    }, [])

    // always fetch checks on either initial load
    // or when we've switched to a new user
    useEffect(() => {
        if (loading === null) {
            dispatchFetchChecks({ skippable: false })
        }
    }, [dispatch])

    // always fetch data when toggling between the
    // current server and all other servers
    useEffect(() => {
        // checking for false here means that the initial
        // null value won't prematurely allow this to happen
        if (loading === false) {
            dispatchFetchChecks({ skippable: false })
        }
    }, [currentUser.show_open_checks_for_everyone])

    // setup resource type tabs
    useEffect(() => {
        if (!!resourceTypes) {
            setTabs([...tabs, ...resourceTypes])
        }
    }, [resourceTypes])

    useEffect(() => {
        if (loading) {
            window.sessionStorage.setItem(PREVENT_LOADER, true)
        } else {
            window.sessionStorage.removeItem(PREVENT_LOADER)
        }

        return () => {
            window.sessionStorage.removeItem(PREVENT_LOADER)
        }
    }, [loading])

    return (
        <div id={`advanced-pos-terminal--${parameterize(self)}`}>
            <TabNav
                tabs={tabs}
                currentTab={currentTab.title}
                prepend={
                    <button
                        id='filter-button'
                        type='button'
                        className='nav-item prepended bg-blue text-white'
                        onClick={handleToggleSelfOrEveryone}
                    >
                        <Icon
                            icon={currentUser.show_open_checks_for_everyone ? 'fa-users' : (debug ? 'fa-user-bounty-hunter' : 'fa-user')}
                            packs={['fa-solid']}
                        />
                    </button>
                }
                append={
                    <NewCheckButton
                        label={<i className='fa fa-plus' />}
                        className='nav-item appended bg-blue text-white'
                        disabled={!transactionsAllowed && currentTab.is_bookable}
                        resourceType={currentTab}
                    />
                }
                handleChange={handleChangeTab}
            />

            <div id={`advanced-pos-terminal--${parameterize(self)}-tab-content`} className='scrollhint'>
                <div className='scrollhint--inner'>
                    <ChecksGrid
                        content={filteredChecks}
                        currentTab={currentTab}
                        loading={loading}
                        onCheckClick={handleCheckClick}
                    />
                </div>
            </div>
        </div>
    )
}
