import React, { Fragment, lazy, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCheckoutTab, selectCreditMethod, setCreditMethod } from '../../../advancedPointOfSaleSlice'
import Terminal from './Terminal'
import Manual from './Manual'

export default function Credit(props) {

    const dispatch     = useDispatch()
    const checkTab     = useSelector(selectCheckoutTab)
    const creditMethod = useSelector(selectCreditMethod)

    const TokenizedCardOnFile     = lazy(() => import('@/features/AdvancedPointOfSale/components/checkout/credit/TokenizedCardOnFile'))
    const PreAuthorizedCardOnFile = lazy(() => import('@/features/AdvancedPointOfSale/components/checkout/credit/PreAuthorizedCardOnFile'))

    const isCardOnFileTemporarilyDisabled = useMemo(() => (
        !!checkTab?.pre_authorized_transaction && checkTab.pre_authorized_transaction.pending_amount_cents > 0
    ), [checkTab])

    const tabs = useMemo(() => {
        const array = [
            { slug: 'terminal', label: 'Terminal' },
            { slug: 'manual', label: 'Manual' },
        ]

        if (!!checkTab?.pre_authorized_transaction) {
            array.splice(0, 0, {
                slug: 'pre_authorized_card_on_file',
                label: isCardOnFileTemporarilyDisabled ? 'Please Wait<br />Payment Pending' : 'Card On Tab',
                disabled: isCardOnFileTemporarilyDisabled,
            })
        }

        if (!!checkTab?.payment_profile?.['recurring.shopperReference']) {
            array.splice(0, 0, { slug: 'tokenized_card_on_file', label: 'Card On File' })
        }

        return array
    }, [checkTab])

    const handleTabChange = ({ slug }) => {
        dispatch(setCreditMethod(slug))
    }

    const renderCreditForm = () => {
        switch (creditMethod) {
            case 'terminal' : return (
                <Terminal
                    {...props}
                    tabs={tabs}
                    currentTab={creditMethod}
                    onTabChange={handleTabChange}
                />
            )

            case 'pre_authorized_card_on_file' : return (
                <PreAuthorizedCardOnFile
                    {...props}
                    tabs={tabs}
                    currentTab={creditMethod}
                    disabled={isCardOnFileTemporarilyDisabled}
                    onTabChange={handleTabChange}
                />
            )

            case 'tokenized_card_on_file' : return (
                <TokenizedCardOnFile
                    {...props}
                    tabs={tabs}
                    currentTab={creditMethod}
                    onTabChange={handleTabChange}
                />
            )

            case 'manual' : return (
                <Manual
                    {...props}
                    tabs={tabs}
                    currentTab={creditMethod}
                    onTabChange={handleTabChange}
                />
            )
        }
    }

    useEffect(() => {
        if (isCardOnFileTemporarilyDisabled) {
            dispatch(setCreditMethod(tabs[1].slug))
        } else {
            dispatch(setCreditMethod(tabs[0].slug))
        }
    }, [checkTab])

    return <Fragment children={renderCreditForm()} />
}
