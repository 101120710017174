import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    selectRecipientType,
    selectSelectedType,
    selectPurchaseFor,
    setStep,
    setRecipientType,
} from '../memberSlice'

import MemberStep from './MemberStep'
import TermsButton from '../TermsButton'
import RecipientRadio from '../RecipientRadio'
import MemberSteps, { RecipientTypes } from '@/lib/Member'

export default function SelectRecipient({
    disableGift=false,
    hideMobileProgressHeader,
    withinGroupModal,
}) {

    const dispatch      = useDispatch()
    const selectedType  = useSelector(selectSelectedType)
    const recipientType = useSelector(selectRecipientType)
    const purchaseFor   = useSelector(selectPurchaseFor)

    const evalNext = () => {
        // return recipientType === RecipientTypes.MYSELF ? MemberSteps.MEMBER_INFORMATION : MemberSteps.GIFTED_MEMBER_INFORMATION
        return MemberSteps.MEMBERSHIP_TERM
    }

    // automatically choose who we're purchasing for and skip
    // to the next step if a purchaseFor value was passed in
    useEffect(() => {
        if (!!purchaseFor && Object.values(RecipientTypes).includes(purchaseFor)) {
            dispatch(setRecipientType(purchaseFor))
            dispatch(setStep(evalNext()))
        }
    }, [])

    return (
        <MemberStep
            disabled={!recipientType}
            nextStep={evalNext()}
            backStep={MemberSteps.SELECT_MEMBER_TYPE}
            hideMobileProgressHeader={hideMobileProgressHeader}
            hideBackStep={!withinGroupModal}
            hideLedger
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 px-0 px-md-4">
                        <div className="text-center">
                            <h2 className="pt-4 pb-4">{selectedType?.name}</h2>
                            <h5 className='font-weight-light text-left' style={{ whiteSpace: "pre-wrap" }}>{selectedType?.public_description}</h5>

                            <TermsButton detailsAndTerms={selectedType.terms} noWrapper />
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 pt-4 px-0 px-md-4">
                        <RecipientRadio
                            label='Purchase For Myself'
                            type={RecipientTypes.MYSELF}
                        />

                        <RecipientRadio
                            label='Purchase As a Gift'
                            type={RecipientTypes.GIFT}
                            disabled={disableGift}
                        />
                    </div>
                </div>
            </div>
        </MemberStep>
    )
}
