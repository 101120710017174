import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment"
import StaffName from '@/features/AdvancedPointOfSale/components/StaffName'
import { approveComp, declineComp } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { useConfirm } from '@/lib/useConfirmHook'
import { calculateSubtotalAmountCents } from '@/features/AdvancedPointOfSale/lib/Items'
import { numberToCurrency } from '@/lib/Number'

export default function CompCard({ comp=null, item=null, handleClose=() => {} }) {

    const dispatch    = useDispatch()
    const { confirm } = useConfirm()
    const tz          = useSelector(selectTimeZone)

    const priceCents = calculateSubtotalAmountCents(item?.item, item?.config)

    const handleClick = async (e) => {
        if (await confirm(`Are you sure you want to ${e.target.name} this comp?`)) {

            if (e.target.name === 'approve') {
                dispatch(approveComp(comp)).then(data => {
                    if (data.success) { handleClose() }
                })
            } else {
                dispatch(declineComp(comp)).then(data => {
                    if (data.success) { handleClose() }
                })
            }
        }
    }

    return !!comp && (
        <div id={`comp-${comp.id}`} className='comp-card p-0'>
            <div className="comp-card-container">
                <div className="d-flex align-items-start justify-content-between p-3 bg-gray8">
                    <h4 className='mb-0 pb-0'>{item.item.name}</h4>

                    {comp.status === 'pending' &&
                        <div className="d-flex justify-content-between">
                            <button
                                children='Decline'
                                className='comp-card-button btn btn-outline-primary mr-2'
                                name='decline'
                                onClick={handleClick}
                            />

                            <button
                                children='Approve'
                                className='comp-card-button btn btn-primary'
                                name='approve'
                                onClick={handleClick}
                            />
                        </div>
                    }
                </div>

                <div className="d-flex align-items-start justify-content-between my-3 px-3">
                    <span>
                        <strong className='pr-2'>CHECK:</strong>{comp.check_name}<br />
                        <strong className='pr-2'>TAB:</strong>{comp.tab_name}
                    </span>

                    <span>
                        {comp.resource_type_name && (<><strong className='pr-2'>RESOURCE:</strong>{comp.resource_type_name}<br/></>)}

                        <strong className='pr-2'>REASON:</strong>{comp.comp_reason.name}
                    </span>
                </div>

                <div className="d-flex align-items-start justify-content-between mb-3 px-3">
                    {comp.notes && (<><br/><span className="font-weight-bold">"{comp.notes}"</span></>)}

                    {comp.status === 'approved' &&
                        <em>Approved by {comp.manager?.name} at {moment.tz(comp.approved_at, tz).format('MMM D, YYYY h:mma z')}</em>
                    }

                    {comp.status === 'declined' &&
                        <em>Declined by {comp.manager?.name } at {moment.tz(comp.declined_at, tz).format('MMM D, YYYY h:mma z')}</em>
                    }
                </div>

                <div className="check-card--footer">
                    <StaffName className="staff" text={comp.server?.name} />
                    <strong>{ numberToCurrency(priceCents / 100) }</strong>
                </div>
            </div>
        </div>
    )
}
