import React, { useEffect } from 'react'
import FormRow from '@/components/Form/FormRow'
import Input from '@/components/Form/Input'
import Select from '@/components/Form/Select'
import Checkbox from '@/components/Form/Checkbox'
import TextArea from '@/components/Form/TextArea'
import Tooltip from '@/components/Form/Tooltip'
import Addresses from '@/components/Locations/LocationAddresses'
import Help from '@/features/Help/Help'
import AdvancedPointOfSaleSettings from '@/components/Locations/settings/AdvancedPointOfSale'
import { validationPatterns } from '@/lib/Errors'

export default function LocationSetupForm({
  form,
  errors,
  imagesUrl,
  directUploadUrl,
  loaded,
  superUser,
  companyAdmin,
  membershipsEnabled,
  isAdvPosStatusMutable,
  handleTabLoaded,
  handleUpdate,
  handleCheckboxUpdate,
  handleManualUpdate,
}) {

  useEffect(() => {
    if (!loaded) { handleTabLoaded() }
  }, [])

  return (
    <>
      <h4>Basic Location Setup<Help articleId='154000155468-setup-tab-basic-setup' /></h4>

      <FormRow>
        <Input
          value={form.name}
          label="Location Name"
          cols={9}
          errors={errors}
          validation={{ 'name': { required: true }}}
          handleChange={handleUpdate}
        />

        <Input
          mask="***"
          name='reservation_prefix'
          value={form.reservation_prefix}
          cols={3}
          handleChange={handleUpdate}
          validation={{ 'reservation_prefix': {
            required: true,
            pattern: validationPatterns.locationPrefix
          }}}
          errors={errors} />
      </FormRow>

      <FormRow>
        <Input
          value={form.website}
          cols={4}
          errors={errors}
          validation={{ 'website': {
            required: true,
            pattern: validationPatterns.url,
          }}}
          handleChange={handleUpdate}
        />

        <Input
          value={form.reservations_page_link}
          cols={4}
          errors={errors}
          req={false}
          validation={{ 'reservations_page_link': {
            required: false,
            pattern: validationPatterns.url,
          }}}
          handleChange={handleUpdate}
        />

        <Input
          value={form.contact_page_link}
          cols={4}
          errors={errors}
          validation={{ 'contact_page_link': {
            required: true,
            pattern: validationPatterns.url,
          }}}
          handleChange={handleUpdate}
        />
      </FormRow>

      <Addresses
        form={form}
        errors={errors}
        handleUpdate={handleUpdate}
        handleCheckboxUpdate={handleCheckboxUpdate}
      />

      <h4>Advanced Location Setup<Help articleId='154000155470-setup-tab-continued' /></h4>

      <FormRow>
        <Input
          value={form.contact_email}
          cols={4}
          errors={errors}
          validation={{ 'contact_email': {
            required: true,
            pattern: validationPatterns.email,
          }}}
          handleChange={handleUpdate}
        />

        <Input
          value={form.contact_name}
          cols={4}
          errors={errors}
          validation={{ 'contact_name': { required: true }}}
          handleChange={handleUpdate}
        />

        <Input
          mask='999-999-9999'
          value={form.contact_phone}
          cols={4}
          errors={errors}
          validation={{ 'contact_phone': {
            required: true,
            pattern: validationPatterns.phone,
          }}}
          handleChange={handleUpdate}
        />
      </FormRow>

      <h3>Taxation</h3>

      <FormRow>
        <Input
          cols='3'
          label='Global Sales Tax Rate'
          value={form.tax_rate}
          type='number'
          min={0}
          max={100}
          step='1'
          append={true}
          errors={errors}
          validation={{ 'tax_rate': {
            required: true,
            pattern: validationPatterns.taxRate
          }}}
          handleChange={handleUpdate}
        >
          <div className="input-group-append">
              <div className="input-group-text">%</div>
          </div>
        </Input>

        <Checkbox
          cols='4'
          className="mt-4 pt-1 pl-3 offset-1"
          name='reservations_taxable'
          label='Reservations Taxable?'
          value={form.reservations_taxable}
          handleChange={handleCheckboxUpdate}
        />
      </FormRow>

      <h3>Reservations</h3>

      <FormRow>
        <Checkbox
          cols='4'
          name='send_text_confirmation'
          label='Send Text Confirmation?'
          value={form.send_text_confirmation}
          handleChange={handleCheckboxUpdate}
        />

        <Checkbox
          cols='8'
          name='auto_complete_stale_reservations'
          label='Auto-complete reservations 15 minutes after their scheduled end time?'
          value={form.auto_complete_stale_reservations}
          handleChange={handleCheckboxUpdate}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          cols='12'
          name='include_payment_link_in_confirmation'
          label={`Include payment link in confirmation ${form.send_text_confirmation ? 'text / email' : 'email'}?`}
          value={form.include_payment_link_in_confirmation}
          handleChange={handleCheckboxUpdate}
        />
      </FormRow>

      <h3>Quoting</h3>

      <FormRow>
        <Select
          name='quote_expiring_soon_hours'
          label='Quote Expiration Reminder'
          cols='3'
          handleChange={handleUpdate}
          req={true}
          options={[
            { value: 24, display: "24 Hours Before" },
            { value: 48, display: "48 Hours Before" },
            { value: 72, display: "72 Hours Before" },
          ]}
          value={form.quote_expiring_soon_hours}
          errors={errors}
          validation={{ 'quote_expiring_soon_hours': { required: true } }}
        />

        <Checkbox
          cols='6'
          className="mt-4 pt-1 pb-0 pl-3"
          name='allow_customer_to_update_quote_status'
          label='Allow customer to accept/reject quote from email?'
          value={form.allow_customer_to_update_quote_status}
          handleChange={handleCheckboxUpdate}
        />
      </FormRow>

      <AdvancedPointOfSaleSettings
        form={form}
        errors={errors}
        imagesUrl={imagesUrl}
        directUploadUrl={directUploadUrl}
        superUser={superUser}
        companyAdmin={companyAdmin}
        isAdvPosStatusMutable={isAdvPosStatusMutable}
        handleUpdate={handleUpdate}
        handleManualUpdate={handleManualUpdate}
      />

      <h3 className='d-flex align-content-center justify-content-between'>
        <span>Memberships</span>
        <Checkbox
          className={`mt-2 ${membershipsEnabled || form.payment_processor_id === 1 ? 'mr-4' : ''}`}
          cols=''
          label=' '
          name='memberships_enabled'
          tooltip={
            membershipsEnabled
              ? 'This feature is permanently enabled'
              : form.payment_processor_id === 1
                ? 'Please contact us about enabling this feature'
                : ''
          }
          disabled={membershipsEnabled || form.payment_processor_id === 1}
          value={form.memberships_enabled}
          handleChange={handleCheckboxUpdate}
        />
      </h3>

      <FormRow>
        {
          form.memberships_enabled && (
            <Checkbox
              cols={4}
              name='memberships_taxable'
              label='Memberships Taxable?'
              value={form.memberships_taxable}
              handleChange={handleCheckboxUpdate}
            />
          )
        }
      </FormRow>

      <h3>Usage Analytics</h3>

      <h4 className='mt-4'>
        Facebook Pixel
        <Tooltip id='facebook' place='right'>
          Facebook pixel code
        </Tooltip>
      </h4>

      <FormRow>
        <TextArea
          name='facebook_pixel'
          handleChange={handleUpdate}
          value={form.facebook_pixel}
          placeholder='<OPTIONAL GOES CODE HERE>'
          cols='12'
          hideLabel={true} />
      </FormRow>

      <h4 className='mt-1'>
        Google Analytics
        <Tooltip id='google' place='right'>
          Google analytics code
        </Tooltip>
      </h4>

      <FormRow>
        <TextArea
          name='google_analytics'
          placeholder='<OPTIONAL GOES CODE HERE>'
          handleChange={handleUpdate}
          value={form.google_analytics}
          cols='12'
          hideLabel={true} />
      </FormRow>
    </>
  )
}
