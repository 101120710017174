import React, { useEffect, useRef } from 'react'
import { Provider } from 'react-redux'
import memberStore from '../../stores/memberStore'
import MemberModalBody from '../../features/Members/MemberModalBody'
import MemberModalStyles from '../../features/Members/MemberModalStyles'
import NotificationList from '@/features/Notifications/NotificationList'

export default function MemberRegisterModal({
    environment,
    companyId,
    locationId,
    locationTimeZone,
    modalStyles,
    membership=null,
    cards=[],
    modalBackgroundImage='',
    occasionOptions=[],
    taxRate=0.0,
    disableGift=false,
    membershipsTaxable,
    rkdReservationFee,
    merchantReservationFee,
    existingMemberInformation={},
    purchaseFor=null,
    returnUrl=null,
    disabled=false,
    withinGroupModal=false,
}) {
    const modalContainerRef = useRef()
    const modalInnerBody    = useRef()
    const paddingPx         = 20;

    const setModalHeight = () => {
        if (!modalContainerRef.current || !modalInnerBody.current) { return }

        // we need to have a tiny delay between the resize event
        // triggering and the styles being set so that the browser
        // has time to calculate window.innerHeight before we use it
        window.setTimeout(() => {
            // set the .member-modal-container's height
            modalContainerRef.current.setAttribute('style', `height: ${window.innerHeight}px; padding:${paddingPx}px;`)

            // set the #member-modal-body.xbm-inner's height MINUS the padding
            modalInnerBody.current.setAttribute('style', `height: ${window.innerHeight - (paddingPx * 2)}px`)
        }, 100)
    }

    useEffect(() => {
        modalInnerBody.current = document.getElementById('member-modal-body')
        setModalHeight()
        window.addEventListener('resize', setModalHeight, false)

        return () => {
            window.removeEventListener('resize', setModalHeight, false)
        }
    }, [])

    return (
        <Provider store={memberStore}>
            <MemberModalStyles modalStyles={modalStyles} />

            <div ref={modalContainerRef} className="member-modal-container">
                <MemberModalBody
                    environment={environment}
                    companyId={companyId}
                    locationId={locationId}
                    locationTimeZone={locationTimeZone}
                    membership={membership}
                    cards={cards}
                    modalBackgroundImage={modalBackgroundImage}
                    occasionOptions={occasionOptions} 
                    taxRate={taxRate} 
                    disableGift={disableGift} 
                    membershipsTaxable={membershipsTaxable}
                    rkdReservationFee={rkdReservationFee}
                    merchantReservationFee={merchantReservationFee}
                    existingMemberInformation={existingMemberInformation}
                    purchaseFor={purchaseFor}
                    returnUrl={returnUrl}
                    disabled={disabled}
                    withinGroupModal={withinGroupModal}
                />
            </div>

            <NotificationList />
        </Provider>
    )
}
