import React from 'react'
import { useSelector } from 'react-redux'
import { parameterize } from 'inflected'
import { selectCurrentCheck } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

export default function CheckoutHeader({
    self='',
    totalDue=0,
    currentPaymentMethod='',
    onClick=() => {}
}) {

    const check = useSelector(selectCurrentCheck)

    return <>
        <h1 className='border-bottom pb-1 mb-2 font-weight-lighter text-truncate'>
            <span>
                {
                    !!check.booking?.resource_name && (
                        <span className='pr-3'>{ check.booking.resource_name }</span>
                    )
                }

                <span className='h5 font-source-sans font-weight-bold'>
                    {check.name}
                </span>
            </span>
        </h1>

        <div id={`advanced-pos-terminal--${parameterize(self)}--navigation`} className='payment-type d-flex justify-content-between pt-2'>
            <div className={`menu-button w-100 pl-0 ${currentPaymentMethod === 'credit' ? 'is-selected' : ''}`.trim()}>
                <button
                    children='Credit / Debit'
                    name="credit"
                    className='btn d-flex justify-content-center text-nowrap'
                    onClick={onClick}
                />
            </div>

            <div className={`menu-button w-100 ${currentPaymentMethod === 'cash' ? 'is-selected' : ''}`.trim()}>
                <button
                    children='Cash'
                    name="cash"
                    className='btn d-flex justify-content-center text-nowrap'
                    onClick={onClick}
                />
            </div>

            <div className={`menu-button w-100 ${currentPaymentMethod === 'check' ? 'is-selected' : ''}`.trim()}>
                <button
                    children='Check'
                    name="check"
                    className='btn d-flex justify-content-center text-nowrap'
                    onClick={onClick}
                />
            </div>

            <div className={`menu-button w-100 pr-0 ${currentPaymentMethod === 'gift_card' ? 'is-selected' : ''} ${totalDue > 0 ? '' : 'disabled'}`.trim()}>
                <button
                    children='Gift Card'
                    name="gift_card"
                    className='btn d-flex justify-content-center text-nowrap'
                    disabled={totalDue > 0 ? false : true}
                    onClick={onClick}
                />
            </div>
        </div>
    </>
}
