// @note: a ruby equivalent of this function lives
// in /app/models/advanced_point_of_sale/item.rb
export function calculateTaxCents(options={}) {
    options = {
        amountCents: 0,
        priceIncludesSalesTax: false,
        salesTaxRate: 0,
        ...options
    }

    let subTotalCents = 0
    let salesTaxCents = 0

    if (options.priceIncludesSalesTax) {
      // Subtractive Sales Tax Formula
      // for 7.5% sales tax
      // $20 - ($20 / (1 + (7.5/100))) = $1.3953488372 (sales tax only)
      // $20 - $1.3953488372 = $18.6046511628 (product amount_cents only)
      // -----------------------------------------------
      // $20 = $18.6046511628 + $1.3953488372 (amount_cents with sales tax included)
      salesTaxCents = options.amountCents - Math.ceil(options.amountCents / (1 + (Number.parseFloat(options.salesTaxRate) / 100.0)))
      subTotalCents = options.amountCents - salesTaxCents
    } else {
      // Additive Sales Tax Formula
      // for 7.5% sales tax
      // $20 * (7.5/100) = $1.5 (sales tax only)
      // $20 + $1.5 = $21.50 (amount_cents plus sales tax)
      salesTaxCents = Number.parseFloat( (options.amountCents * (Number.parseFloat(options.salesTaxRate) / 100.0)).toFixed(2) )
      subTotalCents = options.amountCents
    }

    return [salesTaxCents, subTotalCents]
}


// @note: a ruby equivalent of this function lives
// in /app/models/advanced_point_of_sale/tab.rb
export function calculateSubtotalAmountCents(item=null, itemConfig=null, discount={}) {
    if (!item || !(item?.amount_cents || item.price_cents)) { return 0 }

    // we always return the current balance after-tax amount for bookings
    if (/^(booking|general_booking)$/i.test(item.type)) { return item.price_cents }

    // we always return the current balance after-tax amount for non-F&B and non-bookings
    if (!/^(item)$/i.test(item.type)) { return item.amount_cents }

    let discountCents              = null
    let defaultModifierAmountCents = modifierCentsFor(item, itemConfig, true)
    let modifierAmountCents        = modifierCentsFor(item, itemConfig, false)
    let priceCents                 = Number.parseFloat(item.amount_cents) + defaultModifierAmountCents + modifierAmountCents

    if (item.price_includes_sales_tax) {
        priceCents -= (calculateTaxCents({
            amountCents: priceCents,
            priceIncludesSalesTax: true,
            salesTaxRate: item.sales_tax_rate,
        })?.[0] || 0)
    }

    if (isDiscountable(discount)) {
        if ((discount?.amountType || discount?.amount_type) === 'percent' && !!discount?.amount) {
            discountCents = (priceCents * (Number.parseFloat(discount.amount) / 100)).toFixed()
        }

        // NOTE the discount object used in the discount modal is not in cents
        if (discount?.amountType === 'dollar' && !!discount?.amount) {
            discountCents = (Number.parseFloat(discount.amount) * 100)
        }

        // NOTE the discount object from the database IS in cents
        if (discount?.amount_type === 'dollar' && !!discount?.amount) {
            discountCents = Number.parseFloat(discount.amount)
        }
    }

    if (Object.keys(discount).length > 0) {
        return { priceCents, discountCents }
    } else {
        return priceCents
    }
}

export function calculatePriceAndDiscountedPrice(item={}, discountPackage=null, showAfterTax=true, debug=false, refundedCreditCents=0) {
    let originalPrice, price, additionalCost, additionalRate = 0

    if (item?.amount_type === 'credit' || /^(booking_participant__)+.*$/i.test(item?.type)) {
        return {
            originalPrice: item.total_cents / 100,
            price: (item.total_cents - refundedCreditCents - (item?.auto_gratuity_cents || 0)) / 100,
        }
    }

    if (!!discountPackage?.membership_id && item?.type === 'item') {
        return {
            originalPrice: item.amount_cents / 100,
            price: (item.total_cents - (item?.auto_gratuity_cents || 0)) / 100,
        }
    }

    // only calculate a price discount if necessary (ie, when it hasn't been overridden)
    price = ((showAfterTax
        // booking pricing        // all other types of pricing
        ? (item?.price_cents      || item?.total_cents)
        : (item?.base_price_cents || item?.subtotal_cents)
    ) / 100)

    additionalCost = ((
        /^(booking|general_booking)$/i.test(item?.type)
            ? ((item?.taxes_cents     || 0) + (item?.rkd_fees_cents || 0) + (item?.merchant_fees_cents || 0) + (item?.third_party_fees_cents || 0) + (item?.auto_gratuity_cents || 0))
            : ((item?.sales_tax_cents || 0) + (item?.rkd_fees_cents || 0) + (item?.merchant_fees_cents || 0) + (item?.third_party_fees_cents || 0) + (item?.auto_gratuity_cents || 0))
    ) / 100)

    // reverse calculate the additional rates (could be a tax rate only or taxes with an auto-grat rate, etc.)
    additionalRate = additionalCost > 0 && price > 0
        ? ((additionalCost / price) * 100) / 100
        : 0

    originalPrice = Math.round((showAfterTax
          // booking pricing      // all other types of pricing
        ? (item?.base_price_cents || item?.amount_cents) * (1 + additionalRate)
        : (item?.base_price_cents || item?.amount_cents)
    ) / 100).toFixed(2)

    if (debug) {
        console.log(
            price,
            additionalCost,
            additionalRate,
            originalPrice,
            item
        )
    }

    return { originalPrice, price: price - ((item?.auto_gratuity_cents || 0) / 100) }
}

export function sortByAddedDateTime(data, direction='asc', key='item.added_at') {
    const result = data.slice().sort((_a,_b) => {
        const a = eval(`_a.${key}`)
        const b = eval(`_b.${key}`)

        if (a == undefined) { return -1 }
        if (b == undefined) { return  1 }

        return new Date(a) - new Date(b)
    })

    return /^desc$/i.test(direction) ? result.reverse() : result
}

export function isItemModified(item, check) {
    if (!item || item.item?.type !== 'item') { return false }
    switch(true) {
        case !!item?.notes :
        case (item?.discount?.amount || 0) > 0 :
        case !!(item?.config || []).find((m) => !!m.chit_action) :
        case !!(check.comps || []).find(comp => /approved/i.test(comp.status) && comp.item_uuid === item?.item?.uuid) :
            return true

        default :
            return false
    }
}

export function groupItems(items=[], check=null) {
    let groups = []

    items.forEach((item) => {
        if (isItemModified(item, check)) {
            groups.push(item)
        } else {
            const index = groups.findIndex((group) => (
                (group.item?.id === item.item?.id)
                    && (group.item.total_cents === item.item.total_cents)
                    && group.count
            ))

            if (groups[index]) {
                const count = groups[index].count + 1

                groups[index] = { ...groups[index], count }
            } else {
                groups.push({ ...item, count: 1 })
            }
        }
    })

    if (check?.has_booking_been_split) {
        groups = groups.filter(({ item }) => !/^(booking|general_booking)$/i.test(item.type))
    }

    return groups
}

export function isDiscountable(discount) {
    return !isNaN(parseFloat(discount?.amount)) && parseFloat(discount?.amount) > 0
}

export function modifierCentsFor(item, itemConfig, includeDefaultModifiers=false) {
    // first, always try to grab modifiers from the itemConfig object
    let modifiers = itemConfig?.modifiers

    const modifierPattern = /^(modifier)$/i
    const actionPattern   = includeDefaultModifiers ? /^(null|add)$/i : /^(add)$/i

    // then, if we need to, check to see if we've simply been an array of modifiers
    if (!modifiers && Array.isArray(itemConfig)) {
        const modifiersCount = itemConfig.reduce((total, item) => {
            return total + (modifierPattern.test(item?.type) ? 1 : 0)
        }, 0)

        if (modifiersCount === itemConfig.length) {
            modifiers = itemConfig
        }
    }

    // if modifiers is still unknown at this point, we're assuming that we're
    // pricing an item that hasn't been configured away from its defaults
    if (!modifiers) {
        modifiers = item?.modifiers || item?.default_modifiers || null
    }

    // if modifiers is still unknown at this point, then the item can be assumed to not have any
    if (!modifiers) {
        return 0
    }

    return modifiers.filter((modifier) => (
                        modifier.is_available
                            && actionPattern.test(String(modifier.chit_action))
                            && (
                                includeDefaultModifiers
                                    ? ((modifier?.is_default === undefined && modifier.chit_action === null) || modifier?.is_default === true || modifier?.is_default === null)
                                    : modifier?.is_default === false
                            )
                    ))
                    .reduce((total, modifier) => total + parseFloat(modifier.amount_cents), 0)
}
