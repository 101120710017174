import React, { useEffect, useState } from 'react'
import FormRow from '@/components/Form/FormRow'
import Select from '@/components/Form/Select'
import Input from '@/components/Form/Input'
import { numberToCurrency } from '@/lib/Number'

let fabGuess    = 0
let tipGuess    = 0
let amountGuess = 0

export default function PreAuthorizationAmount({
    amount=0,
    minimumAmount=0,
    formula='FULL', // or BASIC
    location=null,
    booking=null,
    theme=null,
    onChange=() => {},
}) {

    const [selectedAmount, setSelectedAmount] = useState(null)
    const [customAmount, setCustomAmount]     = useState('')

    const basicOptions = [
        { value: 10,       display: numberToCurrency(10)  },
        { value: 25,       display: numberToCurrency(25)  },
        { value: 50,       display: numberToCurrency(50)  },
        { value: 100,      display: numberToCurrency(100) },
        { value: 200,      display: numberToCurrency(200) },
        { value: 300,      display: numberToCurrency(300) },
        { value: 'custom', display: 'Custom' },
    ].filter((o) => o.value >= minimumAmount || o.value === 'custom')

    const dynamicMinimumAmount = (
        Math.max(...[
            minimumAmount,
            ...basicOptions.filter(o => o.value !== 'custom').map(o => Number(o.value))
        ]) + 0.01
    )

    const handleSelectChange = (e) => {
        const value = e.target.value
        setSelectedAmount(value)
        onChange(value === 'custom' ? null : Number(e.target.value))
    }

    const handleCustomChange = (e) => {
        const value = e.target.value
        setCustomAmount(value)
        onChange(Number(value))
    }

    useEffect(() => {
        if (formula === 'FULL' && !!booking) {
            fabGuess    = booking.price_cents * ((location?.adyen_pre_authorized_transaction_estimated_fab_percentage || 0) / 100)
            tipGuess    = (booking.price_cents + fabGuess) * ((location?.adyen_pre_authorized_transaction_estimated_tip_percentage || 0) / 100)
            amountGuess = Number((Math.ceil(((booking.balance_cents + fabGuess + tipGuess) / 100) / 10) * 10).toFixed(2))

            onChange(amountGuess || minimumAmount)
        }

        if (formula === 'BASIC' && selectedAmount === null) {
            const _amount = Math.max(minimumAmount, Math.min(...basicOptions.filter(o => o.value !== 'custom').map(o => Number(o.value))))
            setSelectedAmount(_amount)
            onChange(_amount)
        }
    }, [amount])

    useEffect(() => {
        if (minimumAmount > 300) {
            setSelectedAmount('custom')
        } else {
            setSelectedAmount(basicOptions[0])
        }
    }, [])

    return <>
            {
                formula === 'FULL' && !!booking && !!amount ? <>
                    <div
                        className={`py-3 px-4 ${theme === 'calendar' ? 'my-2 mx-2 text-left rounded border-0 alert-warning text-dark' : 'mx-4 mt-4 mb-2 bg-gray3 text-white rounded border-success'}`}
                        style={theme === 'calendar' ? null : { borderStyle: 'solid', borderTopWidth: 0, borderBottomWidth: 0, borderLeftWidth: '6px', borderRightWidth: '6px' }}
                    >
                        <table className={`table table-sm w-100 ${theme === 'calendar' ? 'text-dark' : 'text-white'} m-0`}>
                            <colgroup>
                                <col width='auto' />
                                <col width='0px' />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td className='pl-1 border-0'>Remaining Balance</td>
                                    <td className='pr-1 border-0'>{ numberToCurrency(booking.balance_cents / 100) }</td>
                                </tr>
                                <tr>
                                    <td className='pl-1 border-0'>Estimated F&amp;B ({location.adyen_pre_authorized_transaction_estimated_fab_percentage}%)</td>
                                    <td className='pr-1 border-0'>{ numberToCurrency(fabGuess / 100) }</td>
                                </tr>
                                <tr>
                                    <td className='pb-3 pl-1 border-0'>Estimated Tip ({location.adyen_pre_authorized_transaction_estimated_tip_percentage}%)</td>
                                    <td className='pb-3 pr-1 border-0'>{ numberToCurrency(tipGuess / 100) }</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className={`pt-2 ${theme === 'calendar' ? 'border-dark' : 'border-top border-thick'} text-right pr-3`}>Amount to Pre-Authorize <sup className='text-gray6'>*</sup></th>
                                    <th className={`pt-2 ${theme === 'calendar' ? 'border-dark' : 'border-top border-thick'} pr-1`}>{ numberToCurrency(amount) }</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    <small className={`d-block px-5 text-right w-100 font-italic ${theme === 'calendar' ? 'text-muted' : 'text-gray4'}`}>
                        * amount is rounded up to the nearest $10.00
                    </small>
                </> : <>
                    <FormRow className='mx-3 pt-4'>
                        <Select
                            cols={selectedAmount === 'custom' ? 6 : 12}
                            label='Amount to Pre-Authorize'
                            value={selectedAmount}
                            options={basicOptions}
                            req
                            handleChange={handleSelectChange}
                        />

                        {
                            selectedAmount === 'custom' && (
                                <Input
                                    cols='6'
                                    label='Custom Amount'
                                    value={customAmount}
                                    placeholder='0.00'
                                    type='number'
                                    min={dynamicMinimumAmount}
                                    step='.01'
                                    autoFocus
                                    req
                                    handleChange={handleCustomChange}
                                />
                            )
                        }
                    </FormRow>
                </>
            }
    </>
}
