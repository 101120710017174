import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDrag } from 'react-dnd'
import useDragScrolling from '@/lib/useDragScrolling'
import moment from 'moment-timezone'
import axios from 'axios'

import { selectResources } from '../Resources/resourcesSlice'
import {
  selectLoadedDates,
  selectLastLoadedDatetime,
  selectLabelWidth,
  selectSizeHeight,
  selectSizeWidth,
  scrollToBooking,
  setSelectedBookingTopOffset,
  setDragStartColumn,
  clearDragClasses,
} from './calendarSlice'
import { setBooking, flagBookingDrawerOpen, setMemberCount, setMembers, setTempMembers } from '../EditBooking/editBookingSlice'
import { setOpen, setStep } from '../Schedule/scheduleSlice'
import { selectTimeZone } from '../Locations/locationSlice'
import { selectSelectedResourceType } from '../ResourceType/resourceTypeSlice'
import { setParentBooking, setChildBookings, setParentBookingResources, setParentBookingModalOpen } from "../ParentBooking/parentBookingSlice"
import { selectPackage } from "../Packages/packageSlice"
import { setActiveDrag, setIsDragging, selectIsGrabbed, setIsGrabbed } from '../CalendarBookings/calendarBookingsSlice'
import { addAlert } from '@/features/Notifications/notificationSlice'

import BookingDragHandle from '@/features/Calendar/BookingDragHandle'
import Buffer from '@/features/Calendar/Buffer'
import { BookingHeader } from '@/features/Calendar/BookingHeader'
import { BookingBody } from '@/features/Calendar/BookingBody'
import { BookingOverlappingMenu } from '@/features/Calendar/BookingOverlappingMenu'

import { parseResourceRow } from '@/lib/Resources'
import { dragTypes } from '@/lib/Drag'
import { assignBookingsToTimeColumns, calculatedDragOutlineClasses } from '@/lib/CalendarTimes'
import { isBookingComplete, isBookingInProgress, isBookingReserved, isBookingHeld, isBookingQuote, statusOf } from '@/lib/Booking'
import { debug } from '@/lib/Debug'

{/* <%#
    Event tile dimensions are styled inline.
    Height is determined by the number of resources booked for the event multiplied by the @sizeHeight variable in pixels, minus 15px.
    Width is determined by the number of time units (15min = 1, 30min = 2) multiplied by the @sizeWidth variable in pixels

    Positioning is being done on with JS at the bottom of the document in tandem with the data-event-start-time and data-event-resource attributes.
    data-event-start-time needs to match the index of the time block (beginning at 1) wherein the event begins,
        i.e. if the event starts at 1:00am, the index of that time block should be 5, as there are 4 time blocks making up the 12:00am hour
        with a total of 96 time blocks in a 24 hour period.
    data-event-resource needs to match the index of the resource (beginning at 1) wherein the event is booked.
%> */}

export function _Booking({ booking, resources, childBookings, pkg }) {

    const { addEventListenerForWindow, removeEventListenerForWindow } = useDragScrolling({
        useElementInsteadOfViewPort: true,
        elementId: 'calendar',
        throttle: 300,
        speed: 15,
        // top, right, bottom, left
        edgeHeights: [110, 250, 110, 250],
        debug: false
    })

    const dispatch             = useDispatch()
    const sizeWidth            = useSelector(selectSizeWidth)
    const sizeHeight           = useSelector(selectSizeHeight)
    const labelWidth           = useSelector(selectLabelWidth)
    const allResources         = useSelector(selectResources)
    const selectedTimeZone     = useSelector(selectTimeZone)
    const selectedResourceType = useSelector(selectSelectedResourceType)
    const isGrabbed            = useSelector(selectIsGrabbed)
    const loadedDates          = useSelector(selectLoadedDates)
    const lastLoadedDatetime   = useSelector(selectLastLoadedDatetime)

    const [displayOverlapUI, setDisplayOverlapUI] = useState(false)
    const [currentTime, setCurrentTime]           = useState(moment.tz(selectedTimeZone))

    const bookingRef     = useRef()
    const overlapMenuRef = useRef()

    const overlappingBookingsList = useCallback(() => {
        if (!booking) { return [] }

        switch(true) {
            // somewhere in the middle of the stack
            case booking.overlappedBy.length > 0 && booking.overlapping.length > 0 :
                const bookingSet = [...booking.overlappedBy, ...booking.overlapping].filter((b, i, a) => a.indexOf(b) == i)
                return assignBookingsToTimeColumns(bookingSet, loadedDates, lastLoadedDatetime, selectedTimeZone)

            // on top of the stack
            case booking.overlappedBy.length === 0 && booking.overlapping.length > 0 :
                return assignBookingsToTimeColumns(booking.overlapping, loadedDates, lastLoadedDatetime, selectedTimeZone)

            // on the bottom of the stack
            case booking.overlappedBy.length > 0 && booking.overlapping.length === 0 :
                return assignBookingsToTimeColumns(booking.overlappedBy, loadedDates, lastLoadedDatetime, selectedTimeZone)

            default :
                return []
        }
    }, [booking.overlappedBy, booking.overlapping, selectedTimeZone])

    const calculateSurfaceLevelFor = useCallback((booking) => {
        if (!booking) { return 2 }
        return booking.overlappedBy.length >= 0
            ? booking.overlapping.length + 2
            : 2
    }, [booking?.overlappedBy, booking?.overlapping])

    const originalSurfaceLevel = useRef(calculateSurfaceLevelFor(booking))

    /**
     * The number of rows this resource needs to take up. The `resources` param is an
     * array of all the resource ids this booking will take, so naturally the number of
     * lanes is just the number of ids found in that array.
     *
     * This is used below to set the `height` style attribute of the booking.
     */
    const numberOfRows = useMemo(() => resources.length, [resources])

    /**
     * The row number of our booking's resource
     *
     * @TODO we could technically do the same thing we are doing with time columns and do this once
     * when the bookings are fetched from the server, however to do that we have to pass the data to
     * the thunk in order to keep passing around which could get a little messy. In theory there should
     * only be up to a dozen or so resources for the largest locations, so parsing over these should
     * be almost instant, whereas time slots have several hundred once they start scrolling.
     */
    const resourceRow = useMemo(() => parseResourceRow(resources, allResources), [resources, allResources])

    /**
     * The time we are incrementing by -- if this is dynamic this can change where it comes from
     */
    const increment = 15

    /**
     * Our top-most z-index level
     */
    const theTippyTop = 1000

    /**
     * The number of columns the booking should take (the width of the card). This is determined by just
     * taking the duration and dividing it by our increment amount (above) since both are in minutes.
     * In other words if each grid block is 15 minutes and the duration is 30 minutes, we need 2 blocks.
     */
    const numberOfColumns = useMemo(() => {
        return (booking.duration + (booking.addon_minutes || 0)) / increment
    }, [booking.duration, booking.addon_minutes])

    const partySizeFor = useCallback((booking) => {
        return booking.group_min && booking.group_max
            ? (booking.group_min == booking.group_max)
                ? booking.group_max
                : `${booking.group_min}-${booking.group_max}`
            : booking.participants
    }, [booking.group_min, booking.group_max, booking.participants])

    /**
     * Handle what happens when a booking is clicked... dispatch to the schedule
     * slice to set the booking and open the drawer
     */
    const handleBookingClick = () => {
        if (booking?.type === 'buffer' || isBookingHeld(booking)) { return }
        setDisplayOverlapUI(false)

        axios.get(`/bookings/${booking.id}/information`)
        .then(({ data }) => {
            dispatch(setBooking({ booking: data.booking, resources: data.resources }))
            dispatch(flagBookingDrawerOpen(booking.id, true))
            dispatch(setSelectedBookingTopOffset((resourceRow * sizeHeight) - sizeHeight))
            dispatch(scrollToBooking(booking))
            dispatch(setStep('3'))
            dispatch(setOpen(true))
            dispatch(setMemberCount(data.membership_ids.length))
            dispatch(setMembers(data.membership_ids.map(id => {
                return {
                    customerId: id[0],
                    memberId: id[1],
                    errors: []
                }
            })))
            dispatch(setTempMembers(data.membership_ids.map(id => {
                return {
                    customerId: id[0],
                    memberId: id[1],
                    errors: []
                }
            })))
        })
    }

    const handleParentBookingClick = () => {
        setDisplayOverlapUI(false)
        dispatch(setSelectedBookingTopOffset((resourceRow * sizeHeight) - sizeHeight))

        axios.get(`/bookings/${booking.id}/information`)
        .then(({ data }) => {
            dispatch(setParentBooking({ booking: data.booking, resources: data.resources }))
            dispatch(setChildBookings({ childBookings: data.child_bookings }))
            dispatch(flagBookingDrawerOpen(booking.id, true))
            dispatch(scrollToBooking(booking))
            dispatch(setParentBookingModalOpen(true))
        })
    }

    const handleOverlapToggle = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDisplayOverlapUI(!displayOverlapUI)
    }

    const handleClickOutside = (e) => {
        if (!overlapMenuRef.current?.contains(e.target) && !bookingRef.current?.contains(e.target)) {
            setDisplayOverlapUI(false)
        }
    }

    const handleSurfaceBooking = (e, booking) => {
        e.preventDefault()
        e.stopPropagation()

        const target       = document.getElementById(`booking-${booking.id}`)
        const currentLevel = Number.parseInt(target.style.zIndex, 10)

        if (target && (currentLevel !== theTippyTop)) {
            // return all sibling events to their original z-index level
            document.querySelectorAll('#calendar .event').forEach((event) => { event.style.zIndex = Number.parseInt(event.dataset.surface_level, 10) })

            // raise the target event to the top of the stack
            target.style.zIndex = theTippyTop
        }

        setDisplayOverlapUI(false)

        if (debug && console) { dispatch(addAlert({ type: 'success', text: window.atob('PGltZyBzcmM9Imh0dHBzOi8vdXBsb2FkLndpa2ltZWRpYS5vcmcvd2lraXBlZGlhL2NvbW1vbnMvdGh1bWIvZi9mYi85ODA2MDQtTi03NzI2RC0wMDJfU3VibWFyaW5lX0VtZXJnZW5jeV9TdXJmYWNpbmdfRHJpbGwuanBnLzgwMHB4LTk4MDYwNC1OLTc3MjZELTAwMl9TdWJtYXJpbmVfRW1lcmdlbmN5X1N1cmZhY2luZ19EcmlsbC5qcGciIHdpZHRoPSIyMzUiIC8+') })) }
    }

    // start/update a hidden timer (i.e., rendering the present time on the booking)
    // this is used to trigger a re-render/CSS status class update every 10 seconds
    useEffect(() => {
        let periodicTimer

        if (!!booking && !isBookingComplete(booking) && (isBookingReserved(booking) || isBookingInProgress(booking))) {
            periodicTimer = window.setInterval(() => {
                setCurrentTime(moment.tz(selectedTimeZone))
            }, 15 * 1000)
        }

        return () => {
            if (periodicTimer) {
                window.clearInterval(periodicTimer)
            }
        }
    }, [booking])

    // our main drag hook, with the format being [<props/attributes>, dragRef, previewRef]
    // the drag ref is applied to the actual handle on the booking card, while the preview ref
    // is applied to the booking card itself -- without the preview ref you won't see the booking being dragged,
    // and wherever you place the drag ref is what triggers the drag on/of, which cannot be on the booking card itself.
    const [{ isDragging }, dragRef, previewRef] = useDrag(() => ({
        // the droppable container/hook has to reference this same type
        type: dragTypes.BOOKING,
        // whatever is in the item object is what is injected into the drop container callback argument i.e. `e.id`
        item: () => {
            addEventListenerForWindow()
            return {
                id:            booking.id,
                duration:      booking.duration,
                addon_minutes: booking.addon_minutes,
                start_time:    booking.start_time,
                is_quote:      booking.is_quote,
                resource_row:  resourceRow,
                resources:     resources
            }
        },
        // set the above first argument attributes to drag events e.g. `isDragging`
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        }),
        end: (item, monitor) => {
            removeEventListenerForWindow()
            dispatch(setActiveDrag(null))
            dispatch(setIsGrabbed(false))
            dispatch(clearDragClasses())
        }
    }), [booking, resources, resourceRow])

    useEffect(() => {
        if (isDragging && !isGrabbed) {
            dispatch(setIsGrabbed(true))
            dispatch(setDragStartColumn(moment.tz(booking.start_time, selectedTimeZone).unix()))
        }
    }, [isDragging])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [bookingRef])

    /**
     * Render the portion of the body that is always the same, regardless of whether
     * it's wrapped in event-inner-border-container which applies to temp bookings.
     *
     * TODO: I think here we is where we can check to see if it's a buffer...
     */
    const renderBody = useMemo(() => {
        if (booking?.type === 'buffer') { return <Buffer buffer={booking} /> }

        return <>
            <div ref={bookingRef} className="event-header">
                <BookingHeader
                    booking={booking}
                    childBookings={childBookings}
                    pkg={pkg}
                    resources={resources}
                    partySizeFor={partySizeFor}
                    overlappingBookingsList={overlappingBookingsList}
                    handleOverlapToggle={handleOverlapToggle}
                />

                {
                    !booking.is_parent && !isBookingHeld(booking) && !isBookingQuote(booking) && (
                        <BookingDragHandle dragRef={dragRef} />
                    )
                }
            </div>

            <BookingBody
                booking={booking}
                pkg={pkg}
                resources={resources}
                timezone={selectedTimeZone}
                columns={numberOfColumns}
                currentTime={currentTime}
            />
        </>
    }, [booking, currentTime])

    const styles = useMemo(() => ({
        height:  `calc(${sizeHeight}px * ${numberOfRows} - 17px - ${booking.is_parent ? '10px' : '0px'})`,
        width:   `calc(${sizeWidth}px * ${numberOfColumns} - ${booking.is_parent ? '20px' : '0px'})`,
        top:     `calc((${resourceRow} - 1) * ${sizeHeight}px + 49px)`,
        left:    `calc((${booking._timeColumn} - 1) * ${sizeWidth}px + ${labelWidth}px)`,
        zIndex:  isGrabbed ? 1 : originalSurfaceLevel.current
    }), [sizeHeight, sizeWidth, numberOfRows, numberOfColumns, resourceRow, booking._timeColumn, isGrabbed, originalSurfaceLevel.current])

    return (
        <div
            ref={previewRef}
            id={`booking-${booking.id}`}
            data-booking={booking.id}
            data-surface_level={originalSurfaceLevel.current}
            className={[
                'event',
                statusOf(booking),
                booking.is_parent ? 'parent' : '',
                overlappingBookingsList().length > 0 ? 'overlap' : '',
                isGrabbed ? 'grabbed' : '',
                isDragging ? 'dragging' : '',
            ].join(' ')}
            style={styles}
        >
            <div
              className={`event-inner ${isBookingHeld(booking) || isBookingQuote(booking) ? 'event-inner-border' : ''}`}
              onClick={booking.is_parent ? handleParentBookingClick : handleBookingClick}
            >
                {
                    isBookingHeld(booking) || isBookingQuote(booking) ? (
                        <div className="event-inner-border-container">
                            { renderBody }
                        </div>
                    ) : renderBody
                }
            </div>

            <BookingOverlappingMenu
                ref={overlapMenuRef}
                overlappingBookingsList={overlappingBookingsList}
                partySizeFor={partySizeFor}
                displayOverlapUI={displayOverlapUI}
                handleSurfaceBooking={handleSurfaceBooking}
            />
        </div>
    )
}

export const Booking = React.memo(_Booking)
