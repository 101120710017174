import React, { useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useConfirm } from '@/lib/useConfirmHook'
import { useManagerOverride } from '@/lib/useManagerOverrideHook'
import { selectCurrentUser, selectLogoutPath, selectReturnUrl } from '@/features/Session/sessionSlice'

import {
    closeSidebar,
    openCashDrawer,
    fetchChecksInAlteration,
    fetchComps,
    selectChecksInAlteration,
    selectCurrentCheck,
    selectComponent,
    selectPendingCompsCount,
    selectDeviceSettings,
    selectInDeviceSetupMode,
    selectIsSidebarOpen,
    selectPrinters,
    setCurrentComponent,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import DeviceFingerPrint from '@/features/AdvancedPointOfSale/components/DeviceFingerPrint'
import LockSessionButton from '@/components/Sessions/LockSessionButton'
import LogoutButton from '@/components/Sessions/LogoutButton'
import { PREVENT_LOADER } from '@/lib/Storage'
import { inAlteration } from '@/features/AdvancedPointOfSale/lib/Checks'

export default function GeneralSidebar({ isSessionUserRestrictedToPos=false, className='p-4' }) {

    const dispatch           = useDispatch()
    const { confirm }        = useConfirm()
    const { authorize }      = useManagerOverride()
    const loadedComponent    = useSelector(selectComponent)
    const inDeviceSetupMode  = useSelector(selectInDeviceSetupMode)
    const currentUser        = useSelector(selectCurrentUser)
    const logoutPath         = useSelector(selectLogoutPath)
    const returnUrl          = useSelector(selectReturnUrl)
    const check              = useSelector(selectCurrentCheck)
    const checksInAlteration = useSelector(selectChecksInAlteration)
    const pendingCompsCount  = useSelector(selectPendingCompsCount)
    const printers           = useSelector(selectPrinters)
    const settings           = useSelector(selectDeviceSettings)
    const isOpen             = useSelector(selectIsSidebarOpen)
    const sidebarRef         = useRef()

    const friendlyName = useMemo(() => {
        let parts = currentUser.name.split(' ')
        return parts.length > 2 ? currentUser.name : parts[0]
    }, [currentUser])

    const cashDrawerAttached = useMemo(() => {
        const printer =  [ ...(printers?.receipt || []), ...(printers?.multi_purpose || []) ].find(printer => printer.id === parseInt(settings.printerId))

        return printer?.has_cash_drawer || false
    }, [printers, settings.printerId])

    const handleClickOutside = (e) => {
        if (
            document.getElementById('advanced-pos--terminal-container').contains(e.target)
            && !sidebarRef.current?.contains(e.target)
            && sidebarRef.current?.getAttribute('data-is-open') === 'true'
        ) {
            dispatch(closeSidebar())
        }
    }

    const handleKeyUp = (e) => {
        if (
            /escape/i.test(e.key)
            && sidebarRef.current?.getAttribute('data-is-open') === 'true'
        ) {
            dispatch(closeSidebar())
        }
    }

    const handleLoadComponent = (name) => {
        if (name.toLowerCase() !== loadedComponent.toLowerCase()) {
            dispatch(setCurrentComponent(name))
        }
        dispatch(closeSidebar())
    }

    const handleSettings = async () => {
        dispatch(closeSidebar())

        if (await authorize()) {
            handleLoadComponent('Settings')
        }
    }

    useEffect(async () => {
        if (isOpen) {
            window.sessionStorage.setItem(PREVENT_LOADER, true)
            await dispatch(fetchChecksInAlteration({ unlessPopulated: false }))
            await dispatch(fetchComps({ pendingCountOnly: true }))
            window.sessionStorage.removeItem(PREVENT_LOADER)
        } else {
            window.sessionStorage.removeItem(PREVENT_LOADER)
        }
    }, [isOpen])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false)
        document.addEventListener('keyup', handleKeyUp, false)

        return () => {
            document.removeEventListener('click', handleClickOutside, false)
            document.removeEventListener('keyup', handleKeyUp, false)
            window.sessionStorage.removeItem(PREVENT_LOADER)
        }
    }, [])

    return isOpen && (
        <aside
            ref={sidebarRef}
            id="advanced-pos--terminal-sidebar--general"
            className={`d-flex flex-column ${className}`.trim()}
            data-is-open={isOpen}
        >
            <div className='d-flex flex-column align-items-start justify-content-between h-100 w-100 overflow-auto'>
                {
                    !inDeviceSetupMode && (
                        <DeviceFingerPrint
                            showBattery={true}
                            showBatteryPercent={true}
                            showLocation={false}
                        />
                    )
                }

                <h5 className="border-bottom pb-1 my-3 d-flex flex-row align-items-center w-100">
                    <span className="nowrap w-100">
                        {`Hi, ${friendlyName}!`}
                    </span>

                    <button
                        type='button'
                        children={<i className="far fa-arrow-rotate-right h5 m-0 pl-3" />}
                        className="btn btn-transparent text-white ml-auto d-block p-0 text-right w-auto"
                        onClick={async () => {
                            if (await confirm('Reload the Application?', { hideHeader: true, size: 'xs' })) {
                                window.location.reload()
                            }
                        }}
                    />

                    {
                        !inDeviceSetupMode && (
                            <button
                                children={<i className="far fa-cog h5 m-0 pl-3" />}
                                type="button"
                                className="btn btn-transparent text-white ml-auto d-block p-0 text-right w-auto"
                                title="Device Settings"
                                onClick={() => {
                                    if (!/staff/i.test(currentUser.role)) {
                                        handleLoadComponent('Settings')
                                    } else {
                                        handleSettings()
                                    }
                                }}
                            />
                        )
                    }
                </h5>

                {
                    !inDeviceSetupMode && <>
                        {
                            /^(super|company_admin|shift_manager|manager|bartender)$/i.test(currentUser.role) && cashDrawerAttached && (
                                <button
                                    type='button'
                                    className='btn btn-dark rounded mb-3'
                                    onClick={() => dispatch(openCashDrawer())}
                                >
                                    <span className='text-gray5 text-center w-100'>
                                        <i className='fas fa-cash-register mr-3' />
                                        Open Cash Drawer
                                    </span>
                                </button>
                            )
                        }

                        <ul className='standard-actions w-100 list-unstyled'>
                            <li>
                                <button
                                    children='Menus'
                                    type='button'
                                    className='btn btn-transparent text-white'
                                    disabled={/^(menu)$/i.test(loadedComponent) && !inAlteration(check)}
                                    onClick={() => handleLoadComponent('DefaultMenu')}
                                />
                            </li>

                            <li>
                                <button
                                    children='Open Checks'
                                    type='button'
                                    className='btn btn-transparent text-white'
                                    onClick={() => handleLoadComponent('OpenChecks')}
                                />
                            </li>

                            {
                                /^(super|company_admin|shift_manager|manager)$/i.test(currentUser.role) && <>
                                    <li>
                                        <button
                                            type='button'
                                            className='btn btn-transparent text-white'
                                            onClick={() => handleLoadComponent('ChecksInAlteration')}
                                        >
                                            Checks in Alteration
                                            {checksInAlteration.length > 0 &&
                                                <span className='badge badge--count text-white bg-danger mb-n1 ml-2'>
                                                    {checksInAlteration.length}
                                                </span>
                                            }
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            type='button'
                                            className='btn btn-transparent text-white'
                                            onClick={() => handleLoadComponent('Comps')}
                                        >
                                            Comps

                                            {
                                                pendingCompsCount > 0 && (
                                                    <span className='badge badge--count text-white bg-danger mb-n1 ml-2'>
                                                        {pendingCompsCount}
                                                    </span>
                                                )
                                            }
                                        </button>
                                    </li>
                                </>
                            }

                            {
                                // everyone except for staff/front-desk
                                !/^(staff)$/i.test(currentUser.role) && (
                                    <li>
                                        <button
                                            children='86'
                                            type='button'
                                            className='btn btn-transparent text-white'
                                            onClick={() => handleLoadComponent('EightySix')}
                                        />
                                    </li>
                                )
                            }

                            <li>
                                <button
                                    children='End Of Shift'
                                    type='button'
                                    className='btn btn-transparent text-white'
                                    onClick={() => handleLoadComponent('EndOfShift')}
                                />
                            </li>

                            {
                                /^(super|company_admin|shift_manager|manager)$/i.test(currentUser.role) && (
                                    <li>
                                        <button
                                            children='Close Of Day'
                                            type='button'
                                            className='btn btn-transparent text-white'
                                            onClick={() => handleLoadComponent('CloseOfDay')}
                                        />
                                    </li>
                                )
                            }
                        </ul>
                    </>
                }

                <ul className={`session-actions w-100 list-unstyled mb-0 ${inDeviceSetupMode ? '' : 'mt-auto'}`}>
                    {
                        !inDeviceSetupMode && (
                            <li>
                                <LockSessionButton
                                    className='btn-primary text-white'
                                    output={<>
                                        <i className='fas fa-user-lock mr-3' />
                                        Switch / Lock User
                                    </>}
                                />
                            </li>
                        )
                    }

                    {
                        (currentUser.is_restricted_to_pos || isSessionUserRestrictedToPos) && (
                            <li>
                                <LogoutButton
                                    className='btn btn-dark rounded'
                                    username={currentUser.username}
                                    logout_path={logoutPath}
                                    output={
                                        <span className='text-gray5'>
                                            <i className='fas fa-sign-out-alt mr-3' />
                                            Session Logout
                                        </span>
                                    }
                                />
                            </li>
                        )
                    }

                    {
                        !!returnUrl && !currentUser.is_restricted_to_pos && !isSessionUserRestrictedToPos &&(
                            <li>
                                <a
                                    href={returnUrl}
                                    type='button'
                                    className='btn btn-warning rounded'
                                >
                                    <span className='text-black'>
                                        <i className='fas fa-turn-down-left mr-3' />
                                        Return to Admin
                                    </span>
                                </a>
                            </li>
                        )
                    }
                </ul>
            </div>
        </aside>
    )
}
