import React from 'react'
import { useDispatch } from 'react-redux'
import { useConfirm } from '@/lib/useConfirmHook'
import { deleteBuffer } from '@/features/CalendarBookings/calendarBookingsSlice'
import { debug } from '@/lib/Debug'

export default function Buffer({ buffer }) {

    const dispatch    = useDispatch()
    const { confirm } = useConfirm()

    const handleDelete = async () => {
        if (!(await confirm('Are you sure you want to delete this buffer?<br /><br />This action cannot be undone.'))) {
            return
        }

        dispatch(deleteBuffer(buffer))
    }

    return (
        <>
            <div className="event-header">
              <div className="party-name">BUFFER</div>

              { /^manual$/.test(buffer.buffer_type) &&
                  <div className="party-name ml-auto">
                      <i className="fas fa-hammer" />
                  </div>
              }
            </div>
            <div className="event-body">
                <div className="booking-status-icons w-100 d-block">
                    <div
                        className="booking-status-icon trash float-right"
                        title="Delete Buffer"
                        style={{ background: 'darkgrey' }}
                        onClick={handleDelete}
                    >
                        <i className="far fa-trash" />
                    </div>
                    { debug && <pre className='text-white'>ID: {buffer.id.replace(/^buffer-/, '')}</pre> }
                </div>
            </div>
        </>
    )
}
